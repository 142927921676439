export const PRODUCT_CARD_WIDTH = 300;
export const PRODUCT_CARD_HEIGHT = 300;

export const CATEGORY_IMAGE_WIDTH = 592;
export const CATEGORY_IMAGE_HEIGHT = 300;

export const BANNER_IMAGE_WIDTH = 944;
export const BANNER_IMAGE_HEIGHT = 550;

export const MOBILE_CATEGORY_IMAGE_WIDTH = 350;
export const MOBILE_CATEGORY_IMAGE_HEIGHT = 250;

export const MOBILE_BANNER_IMAGE_WIDTH = 390;
export const MOBILE_BANNER_IMAGE_HEIGHT = 220;

export const TESTIMONIALS_IMAGE_WIDTH = 70;
export const TESTIMONIALS_IMAGE_HEIGHT = 70;

export const MOBILE_TESTIMONIALS_IMAGE_WIDTH = 70;
export const MOBILE_TESTIMONIALS_IMAGE_HEIGHT = 70;

export const DEFAULT_LAYOUT_SELECTION = 2;
export const DEFAULT_NAVBAR_LAYOUT_SELECTION = 'PH5';
export const DEFAULT_SLIDER_LAYOUT_SELECTION = 'HB5';
export const DEFAULT_PRODUCTLIST_LAYOUT_SELECTION = 'PS6';
export const DEFAULT_CATEGORYLIST_LAYOUT_SELECTION = 'CS5';
export const DEFAULT_TESTIMONIALS_LAYOUT_SELECTION = 'Testimonial17';
export const DEFAULT_TEXT_LAYOUT_SELECTION = 'Text1';

export const SHOW_ALL_PRODUCTS_IN_HOME = false;

export const SECTION_HEADING_CLASSNAME = 'font-semibold';
export const SLIDER_TOP_PADDING = 'pt-0';
export const LAYOUT_CONFIG = {
  productList: {
    ShowTaxTag: false,
  },
};

export const COMMON_TOAST_MESSAGE = {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getToastContent: (productTitle: string, quantity: string): string => {
    return `${productTitle} has been successfully added to your bag`;
  },
};
