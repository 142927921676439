import StoreInfo from '@common/models/StoreInfo';
import { CustomPagesSliceType } from '@common/store/customPages/slice';
import { FeaturesSliceType } from '@common/store/features/slice';
import { FilterBarSliceType } from '@common/store/filterBar/slice';
import { PageHeaderSliceType } from '@common/store/pageHeader/slice';
import { PoliciesSliceTosType } from '@common/store/policiesTos/slice';
import { getActiveNavbar } from '@common/utils';
import { isMobile } from '@common/utils';
import { getBuyerJWTToken } from '@common/utils/token';
import Instagram from '@images/Finstagram.svg';
import LinkedIn from '@images/Flinkedin.svg';
import Email from '@images/footerEmail.svg';
import Pinterest from '@images/Fpinterest.svg';
import Youtube from '@images/Fyoutube.svg';
import Location from '@images/Location.svg';
import Phone from '@images/Phone.svg';
import facebook from '@images/sFacebook.svg';
import Twitter from '@images/STwitter.svg';
import Image from '@lib/Image';
import Link from '@lib/Link';
import React from 'react';
import { connect } from 'react-redux';

import { RootState } from '../../../../common/store';
import { context } from '../../../../common/utils/constants';

interface FooterProps {
  storeInfo: StoreInfo;
  customPages: CustomPagesSliceType;
  features: FeaturesSliceType;
  filterBar: FilterBarSliceType;
  policies: PoliciesSliceTosType;
  pageHeader: PageHeaderSliceType;
  pageHeaderID: number;
}

const Footer: React.FunctionComponent<FooterProps> = ({
  storeInfo,
  features,
  filterBar,
  policies: { policyTos },
  pageHeader,
  pageHeaderID,
}) => {
  const arePoliciesPresent = Object.keys(policyTos).length > 0;
  const quickLinks =
    'md:text-left md:w-full w-fit underline md:mx-0 mb-16 flex text-footer-link text-14 leading-21 font-normal';
  const header = 'md:text-left text-footer-heading leading-24 text-16 mb-24 font-bold';
  const socialIcons = 'w-20 min-w-20 h-20 min-h-20';
  const SocialIcons = () => {
    return (
      <div className="flex w-full justify-start md:border-t border-none border-white border-opacity-10 md:border-opacity-0">
        <div className="flex gap-20 md:gap-34 ml-20 md:ml-32 w-fit items-center h-fit">
          {storeInfo?.social?.facebook ? (
            <a href={storeInfo?.social?.facebook} target="_black()">
              <Image
                noDefaultDimensions={true}
                src={facebook}
                alt="Facebook"
                className={socialIcons}
                id="footer-facebook-link"
              />
            </a>
          ) : null}
          {storeInfo?.social?.twitter ? (
            <a href={storeInfo?.social?.twitter} target="_black()">
              <Image
                noDefaultDimensions={true}
                src={Twitter}
                alt="Twitter"
                className={socialIcons}
                id="footer-twitter-link"
              />
            </a>
          ) : null}
          {storeInfo?.social?.instagram ? (
            <a href={storeInfo?.social?.instagram} target="_black()">
              <Image
                noDefaultDimensions={true}
                src={Instagram}
                alt="Instagram"
                className={socialIcons}
                id="footer-instagram-link"
              />
            </a>
          ) : null}
          {storeInfo?.social?.pinterest ? (
            <a href={storeInfo?.social?.pinterest} target="_black()">
              <Image
                noDefaultDimensions={true}
                src={Pinterest}
                alt="Pinterest"
                className={socialIcons}
                id="footer-pinterest-link"
              />
            </a>
          ) : null}
          {storeInfo?.social?.youtube ? (
            <a href={storeInfo?.social?.youtube} target="_black()">
              <Image
                noDefaultDimensions={true}
                src={Youtube}
                alt="Youtube"
                className={socialIcons}
                id="footer-youtube-link"
              />
            </a>
          ) : null}
          {storeInfo?.social?.linkedin ? (
            <a href={storeInfo?.social?.linkedin} target="_black()">
              <Image
                noDefaultDimensions={true}
                src={LinkedIn}
                alt="LinkedIn"
                className={socialIcons}
                id="footer-linkedin-link"
              />
            </a>
          ) : null}
        </div>
      </div>
    );
  };
  const pageHeaderLayout = pageHeader[pageHeaderID]?.pageHeader;
  return (
    <div className="w-full bg-footer-bg" color-customisation="footer-bg" id="page-footer">
      <div className="w-full container mx-auto pt-50 pb-30">
        <div>
          <div className="md:justify-between justify-center">
            <div className="grid grid-cols-1 sm:grid-cols-4 gap-1 md:px-4">
              <div className="mb-32 md:mb-0">
                <div className={header} font-customisation="section-heading" color-customisation="footer-heading">
                  Policies
                </div>
                {arePoliciesPresent && !policyTos?.cancelAndRefund && (
                  <Link
                    to="/policy/cancellation"
                    className={quickLinks}
                    font-customisation="para-text"
                    color-customisation="footer-links"
                    id="footer-cancellation-refund"
                  >
                    Cancellation &amp; Refund
                  </Link>
                )}
                {arePoliciesPresent && !policyTos?.termsAndConditions && (
                  <Link
                    to="/policy/tnc"
                    className={quickLinks}
                    font-customisation="para-text"
                    color-customisation="footer-links"
                    id="footer-terms-conditions"
                  >
                    Terms & Conditions
                  </Link>
                )}
                {arePoliciesPresent && !policyTos?.shippingAndDelivery && (
                  <Link
                    to="/policy/shipping"
                    className={quickLinks}
                    font-customisation="para-text"
                    color-customisation="footer-links"
                    id="footer-shipping-delivery"
                  >
                    Shipping &amp; Delivery
                  </Link>
                )}
                {arePoliciesPresent && !policyTos?.privacy && (
                  <Link
                    to="/policy/privacy"
                    className={quickLinks}
                    font-customisation="para-text"
                    color-customisation="footer-links"
                    id="footer-privacy"
                  >
                    Privacy
                  </Link>
                )}
              </div>
              <div className="mb-32 md:mb-0">
                <div className={header} font-customisation="section-heading" color-customisation="footer-heading">
                  Account
                </div>
                {features?.features?.buyerLogin && getBuyerJWTToken() && (
                  <Link
                    to="/account/profile"
                    className={quickLinks}
                    font-customisation="para-text"
                    color-customisation="footer-links"
                    id="footer-profile"
                  >
                    Profile
                  </Link>
                )}
                <Link
                  to="/track-order"
                  className={quickLinks}
                  font-customisation="para-text"
                  color-customisation="footer-links"
                  id="footer-track-order"
                >
                  Track Order
                </Link>
              </div>
              <div className="mb-32 md:mb-0">
                <div className={header} font-customisation="section-heading" color-customisation="footer-heading">
                  Company
                </div>
                {getActiveNavbar('About Us', filterBar) && (
                  <Link
                    to="/aboutus"
                    className={quickLinks}
                    font-customisation="para-text"
                    color-customisation="footer-links"
                    id="footer-about-us"
                  >
                    About Us
                  </Link>
                )}
                {features.features?.contactUsPage && getActiveNavbar('Contact Us', filterBar) ? (
                  <Link
                    to="/contactus"
                    className={quickLinks}
                    font-customisation="para-text"
                    color-customisation="footer-links"
                    id="footer-contact-us"
                  >
                    Contact Us
                  </Link>
                ) : null}
                {getActiveNavbar('FAQs', filterBar) && (
                  <Link
                    to="/faqs"
                    className={quickLinks}
                    font-customisation="para-text"
                    color-customisation="footer-links"
                    id="footer-faqs"
                  >
                    FAQs
                  </Link>
                )}
              </div>
              <div>
                <div className={header} font-customisation="section-heading" color-customisation="footer-heading">
                  Contact Us
                </div>
                <div className=" text-priceText ">
                  {storeInfo?.contactInfo?.number ? (
                    <p
                      className={quickLinks}
                      font-customisation="para-text"
                      color-customisation="footer-links"
                      id="footer-contact-number"
                    >
                      <Image noDefaultDimensions={true} src={Phone} alt="Phone" className="mr-14" />
                      <a href={`tel:${storeInfo?.contactInfo?.number}`}>{storeInfo?.contactInfo?.number}</a>
                    </p>
                  ) : null}
                  <p
                    className={quickLinks}
                    font-customisation="para-text"
                    color-customisation="footer-links"
                    id="footer-contact-mail"
                  >
                    <Image noDefaultDimensions={true} src={Email} alt="Email" className="mr-14" />
                    <span className="max-w-200 break-words">
                      <a
                        href={`${isMobile() ? 'mailto:' : 'https://mail.google.com/mail/?view=cm&fs=1&tf=1&to='}${
                          storeInfo?.contactInfo?.email
                        }`}
                        className="underline"
                      >
                        {storeInfo?.contactInfo?.email}
                      </a>
                    </span>
                  </p>
                  <p
                    className={quickLinks + 'flex items-start'}
                    font-customisation="para-text"
                    color-customisation="footer-links"
                    id="footer-contact-address"
                  >
                    <Image noDefaultDimensions={true} src={Location} alt="Location" className="mr-14 mt-2" />
                    <span className="max-w-200 break-words">{storeInfo?.contactInfo?.address}</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          {storeInfo?.hasSocialLinks ? (
            <div className="hidden">
              <SocialIcons />
            </div>
          ) : null}
        </div>
        <div className="flex justify-between flex-col md:flex-row md:items-end mt-16 pt-20 border-t border-divider">
          <div className="flex items-center">
            <Link to="/" className="flex rounded-md">
              <div className="" id="footer-logo">
                {pageHeaderLayout?.logo ? (
                  <img
                    src={pageHeaderLayout?.logo}
                    alt={storeInfo?.storename}
                    className="overflow-hidden rounded-md object-contain h-44"
                    loading="lazy"
                  />
                ) : (
                  <div className="md:font-medium text-20 text-textColor ">{storeInfo?.storename}</div>
                )}
              </div>
            </Link>
            <SocialIcons />
          </div>
          <div className="flex flex-wrap mt-30 md:mt-0">
            <span className="text-subTextColor gap-4 md:gap-0 flex flex-wrap  items-center text-14 leading-18 font-medium">
              ©{context.MERCHANT_HOSTNAME}
              <span className="flex ml-4 items-center gap-4">
                {new Date().getFullYear()}
                <span className="h-4 w-4 block border bg-subTextColor rounded-full"></span>
                All rights reserved
              </span>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect(({ pageHeader }: RootState) => ({ pageHeader }))(Footer);
